import styled, { css } from 'styled-components';

export default styled.div`
  ${({ theme, showForm }) => {
    const { media } = theme;
    return css`
      opacity: ${showForm ? 1 : 0} !important;
      
      ${media.tablet.up} {
        h1 {
          font-size: 42px;
        }
      }

      ${media.mobile.down} {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;

        .address-form {
          &__container {
          }
        }
      }
      .page-header {
        &__titles {
          display: flex;
          flex-direction: column;
          align-items: start;
          gap: 10px;
        }
      }

      .address-form__input {
        & > input {
          opacity: 1 !important;
        }
      }
    `;
  }};
`;
