import styled, { css } from 'styled-components';

export const ResumeAddressStyled = styled.div.attrs({
  className: 'resume-address',
})`
  ${({ theme }) => {
    const { colors = {}, media = {} } = theme;

    return css`
      display: flex;
      flex-direction: row;
      padding: 20px;
      ${media.mobile.down} {
        padding: 10px;
        text-align: center;
        flex-direction: column;
      }

      .small-loader {
        height: 24px;
        width: 24px;
        border: 3px solid black;
        border-right-color: transparent;
        border-radius: 50%;
        margin-left: 15px;
      }

      .resume-address {
        &__text {
          font-weight: 700;
        }
        &__title {
          padding-right: 5px;
          align-items: center;
          color: ${colors.primary.darkBlue};
          display: flex;
          font-size: 16px;
          font-weight: 700;
          justify-content: center;
          line-height: 16px;
          margin-bottom: 0.5rem;

          ${media.tablet.up} {
            justify-content: flex-start;
          }

          svg {
            display: inline-block;
            height: 22px;
            margin-right: 0.5rem;
            position: relative;
            top: 1px;
          }
        }

        &__button {
          background: none;
          border: 0;
          color: ${colors.primary.darkBlue};
          cursor: pointer;
          font-size: 16px;
          line-height: 17px;
          padding: 0;
          margin: 0;

          span {
            color: ${colors.primary.darkBlue};
            font-weight: 500;
            margin-left: 5px;
            text-decoration: underline;
          }
        }
      }
    `;
  }}
`;
