import styled, { css } from 'styled-components';
import convertHexToRgba from '~/helpers/convertHexToRgba';

export default styled.form.attrs({
  className: 'address-form',
})`
  ${({ theme }) => {
    const { colors, media } = theme;

    return css`
      padding-top: 1rem;

      .address-form {
        &__container {
          background-color: ${colors.primary.white};
          display: flex;
          justify-content: space-between;
          height: 3.125rem;
          max-width: 20rem;
          position: relative;

          ${media.tablet.up} {
            max-width: 46.75rem;
            height: 3.75rem;
          }

          & > .icon {
            position: absolute;
            top: 0;
            left: 1rem;
            bottom: 0;
            margin: auto;
            z-index: 1;
            pointer-events: none;
          }

          &:hover {
            .address-form__control {
              border-color: ${colors.borders.focus};
              box-shadow: 0 0 0 2px ${colors.borders.focus};
            }
          }
        }

        &__inputs {
          position: absolute;
          inset: 0;
          z-index: 0;
        }

        &__control {
          height: 100%;
          border: 1px solid ${colors.primary.darkBlue};
          background-color: transparent;
          border-radius: 2rem;
          padding-left: 2.75rem;
          padding-right: 4rem;

          ${media.tablet.up} {
            padding-left: 3rem;
            padding-right: 16.25rem;
          }

          &--is-focused {
            border-color: ${colors.borders.focus};
            box-shadow: 0 0 0 2px ${colors.borders.focus};
          }
        }

        &__input {
          position: relative;
          width: 100%;
          height: 100%;

          & > * {
            height: 100%;
          }
        }

        &__button {
          &__tablet-up,
          &__mobile {
            position: absolute;
            top: 0.25rem;
            right: 0.25rem;
            bottom: 0.25rem;
            display: grid;
            place-content: center;
            text-transform: uppercase;
          }

          &__tablet-up {
            max-width: 15.5rem;

            ${media.mobile.down} {
              display: none;
            }
          }

          &__mobile {
            max-width: 3.25rem;

            ${media.tablet.up} {
              display: none;
            }
          }
        }

        &__menu {
          line-height: 1.25;
          margin-left: 2.5rem;
          width: 65%;

          ${media.mobile.down} {
            font-size: 1rem;
            margin-left: 1.5rem;
            width: 85%;
          }

          .icon--map-marker {
            fill: ${colors.primary.darkBlue};
            height: 1rem;
            margin-right: 1rem;
            width: 1rem;
          }
        }

        &__option--is-selected {
          background-color: ${convertHexToRgba(colors.primary.royalBlue, 0.25)};
          color: ${colors.primary.white};

          .icon--map-marker {
            fill: ${colors.primary.white};
          }
        }
      }
    `;
  }};
`;
