import React, { FC, FormEvent, FormHTMLAttributes } from 'react';
import AutoComplete from './AutoComplete';
import Button from '../Button';
import AddressFormStyled from './AddressFormStyled';
import { Address } from '../../types/address';
import Location from '../Icons/fill/Location';
import useWindowSize from '~/hooks/useWindowSize';
import { ArrowRight } from '../Icons/fill';

interface Props extends FormHTMLAttributes<HTMLFormElement> {
  apiVersion: string;
  selectedAddress: Address;
  setSelectedAddress: (address: Address) => void;
  handleInputChange: (event: FormEvent<HTMLInputElement>) => void;
  buttonState: {
    disabled: boolean;
    loading: boolean;
  };
  onSubmit: (event: FormEvent) => void;
  placeholder?: string;
  placeholderMobile?: string;
  hideIcon?: boolean;
  buttonText?: string;
  useIconButton?: boolean;
}

const AddressForm: FC<Props> = ({
  apiVersion,
  selectedAddress,
  setSelectedAddress,
  buttonState,
  onSubmit,
  placeholder,
  placeholderMobile,
  hideIcon,
  buttonText,
  useIconButton,
  ...props
}) => {
  const onKeyDown = (e) => !buttonState.disabled && e.keyCode === 13 && onSubmit(e);
  const { mobile } = useWindowSize();

  const getButtonText = (isMobile: boolean) => {
    if (useIconButton) return <ArrowRight />;
    if (buttonText) return buttonText;

    return isMobile ? 'Go' : 'Check availability';
  };

  return (
    <AddressFormStyled {...props} onKeyDown={onKeyDown} title={selectedAddress?.label}>
      <div className="address-form__container">
        { !hideIcon && <Location /> }
        <AutoComplete
          selectedAddress={selectedAddress}
          setSelectedAddress={setSelectedAddress}
          placeholder={mobile && placeholderMobile ? placeholderMobile : placeholder}
          apiVersion={apiVersion}
        />
        <Button
          {...buttonState}
          className="address-form__button address-form__button__tablet-up"
          type="submit"
          onClick={onSubmit}
          data-testid="btn-check-address"
          data-chsn-reward-click="cart service check"
        >
          {getButtonText(false)}
        </Button>
        <Button
          {...buttonState}
          className="address-form__button__mobile"
          type="submit"
          onClick={onSubmit}
          data-chsn-reward-click="cart service check"
          data-testid="btn-check-address-mobile"
        >
          {getButtonText(true)}
        </Button>
      </div>
    </AddressFormStyled>
  );
};

AddressForm.displayName = 'AddressForm';

export default AddressForm;
