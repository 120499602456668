import React, { FC } from 'react';
import MapMarkerAlt from '~/components/Icons/outline/MapMarkerAlt';
import { ResumeAddressStyled } from './ResumeAddressStyled';
import { useSelector } from 'react-redux';
import { ReduxState } from '~/types/redux';
import SmallCircleLoader from '~/components/Button/components/SmallCircleLoader';

interface Props {
  wrap?: boolean;
  title?: string;
  loadingState?: boolean;
}

const ResumeAddress: FC<Props> = ({ title }) => {
  const { address } = useSelector((store: ReduxState) => store);
  const addressText = address?.install?.address2
    ? `${address?.install?.address} ${address?.install?.address2}, ${address?.install?.postalCode}`
    : `${address?.install?.address}, ${address?.install?.postalCode}`;

  return (
    <ResumeAddressStyled>
      <span className="resume-address__title">
        <MapMarkerAlt /> {title}
      </span>
      {!address?.install?.address && <SmallCircleLoader />}
      {address?.install?.address && <span className="resume-address__text" data-testid="resume-modal-address">{addressText?.toUpperCase()}</span>}
    </ResumeAddressStyled>
  );
};

ResumeAddress.displayName = 'ResumeAddress';

ResumeAddress.defaultProps = {
  wrap: true,
  title: 'Service address:',
};

export default ResumeAddress;
