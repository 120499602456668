import { useEffect, useState } from 'react';
import theme from '~/styles/theme';

const { xsMobile, mobile, tablet, desktop } = theme.media;

const xsMobileQuery = xsMobile.only.replace('@media ', '');
const mobileQuery = mobile.only.replace('@media ', '');
const tabletQuery = tablet.only.replace('@media ', '');
const desktopQuery = desktop.only.replace('@media ', '');

interface WindowSize {
  width: number;
  height: number;
  xsMobile: boolean;
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
}

const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
    xsMobile: false,
    mobile: false,
    tablet: false,
    desktop: false,
  });

  useEffect(() => {
    const handleResize = (): void => {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
        xsMobile: window.matchMedia(xsMobileQuery).matches,
        mobile: window.matchMedia(mobileQuery).matches,
        tablet: window.matchMedia(tabletQuery).matches,
        desktop: window.matchMedia(desktopQuery).matches,
      });
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
