import React, { FC, useEffect, useState } from 'react';
import Head from 'next/head';
import PageHeader from '~/components/PageHeader';
import { Star, StopwatchRed } from '~/components/Icons/outline';
import AddressCheckStyled from '~/styles/pages/AddressCheckStyled';
import OneColumn from '~/components/Layout/OneColumn';
import useAddressCheckPage from '~/hooks/pages/useAddressCheck';
import { Preamp } from '@red-digital/bricks';
import { useDispatch, useSelector } from 'react-redux';
import { setCallInNumber } from '~/redux/actions/common';
import { setAmexOffer } from '~/redux/actions/global';
import { DEFAULT_SUBTITLE } from '~/constants/address';
import AddressForm from '~/components/AddressForm';
import Modal from '~/components/Modal';
import ResumeModal from '~/components/Modal/screens/Resume';
import { ModalKeys, Modals } from '~/types/products';
import Cookies from 'js-cookie';
import clientSide from '~/helpers/clientSide';
import { ReduxState } from '~/types/redux';
import { trackEvent } from '~/helpers/tracking';
import { setNewRelicCustomAttribute } from '~/helpers/newrelic';

const iconMap = {
  star: Star,
  stopwatch: StopwatchRed,
};

const AddressCheck: FC = (): JSX.Element => {
  const [activeModal, setActiveModal] = useState<Modals>();
  const { resume, monarch, landingPage, token, address, cookiePass, quiz } = useSelector((store: ReduxState) => store);
  const { determineResumeQuote } = monarch;
  const { apiVersion, selectedAddress, setSelectedAddress, handleFormSubmit, handleInputChange, buttonState } =
    useAddressCheckPage();
  const cookiePassFlow = token && address?.install;
  const dispatch = useDispatch();
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (clientSide && determineResumeQuote === true && !quiz?.quizStarted) {
      const declinedResume =
        window?.sessionStorage?.getItem('declinedResume') ||
        resume?.acceptedResume === false ||
        landingPage ||
        cookiePassFlow;
      const completedResume = resume?.completed === true;
      if (Cookies.get('quoteId') && !declinedResume && !completedResume) {
        setActiveModal(ModalKeys.RESUME);
        trackEvent({
          action: 'elementViewed',
          data: {
            elementType: 'MODAL',
            location: 'ADDRESS CHECK',
            position: 'Resume Modal',
            text: 'Resume Modal ',
          },
        });
      }
      if (declinedResume) {
        setNewRelicCustomAttribute([{ name: 'declinedResume', value: 'true' }]);
      }
    }
  }, [determineResumeQuote]);

  useEffect(() => {
    const isAmexOffer = window?.location?.href?.toLowerCase().includes('amex');
    if (isAmexOffer) {
      Cookies.set('isAmexOffer', true);
      dispatch(setCallInNumber('877-837-6222'));
      dispatch(setAmexOffer(true));
    } else {
      Cookies.set('isAmexOffer', false);
    }
  }, []);

  // Wait to show address check form until we've ensured a user is not in the cookie pass/Allconnect flow
  useEffect(() => {
    if (!cookiePass) {
      setShowForm(true);
    }

    // Fallback after 2 seconds so that a user never sits on /buy unable to submit an address
    setTimeout(() => {
      setShowForm(true);
    }, 2000);
  }, [cookiePass]);

  const getTitle = (copy: string) => {
    // Show specific copy when product is selected on landing page
    if (clientSide && window?.sessionStorage?.getItem('selectedProductId')) {
      return 'Enter your address to continue checkout and view exclusive offers';
    }
    return copy ?? 'Enter your address to view plans and exclusive offers.';
  };

  const getSubtitle = (enabled: string, copy: string) => {
    // Show specific copy when product is selected on landing page
    if (clientSide && window?.sessionStorage?.getItem('selectedProductId')) {
      return 'You may be eligible for special offers, like expert installation and a Wi-Fi router included in your plan.';
    }
    return enabled !== 'false' ? copy || DEFAULT_SUBTITLE : '';
  };

  return (
    <OneColumn>
      <Head>
        <title>Address check</title>
      </Head>
      <AddressCheckStyled showForm={showForm}>
        <Preamp placement={'3Vo2ctyZJYhrwolHwbyJ58'}>
          {(metadata) => {
            const Icon = iconMap[metadata?.subtitle?.icon] || Star;
            return (
              <>
                <PageHeader
                  title={getTitle(metadata?.title?.copy)}
                  subtitle={{
                    icon: <Icon />,
                    text: getSubtitle(metadata?.subtitle?.enabled, metadata?.subtitle?.copy),
                  }}
                  headingLevel={metadata?.headingLevel || 1}
                />
                <AddressForm
                  apiVersion={apiVersion}
                  onSubmit={handleFormSubmit}
                  selectedAddress={selectedAddress}
                  setSelectedAddress={setSelectedAddress}
                  handleInputChange={handleInputChange}
                  buttonState={buttonState}
                  placeholder="Enter your address"
                  {...metadata}
                />
              </>
            );
          }}
        </Preamp>
        <Modal zIndex={10000} visible={Boolean(activeModal)}>
          <ResumeModal
            setActiveModal={setActiveModal}
            visible={activeModal === ModalKeys.RESUME}
            apiVersion={apiVersion}
          />
        </Modal>
      </AddressCheckStyled>
    </OneColumn>
  );
};

AddressCheck.displayName = 'AddressCheck';
export default AddressCheck;
